import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContextex } from '../context/useContext';
import { userFaqPage } from '../store/action';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FAQItem = ({ question, answer, isActive, onClick }) => {    

    return (
        <div className={`flat-toggle ${isActive ? 'active' : ''}`}>
            <h4 className={`toggle-title ${isActive ? 'active' : ''}`} onClick={onClick}>
                {question}
            </h4>
            {isActive && (
                <div >
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
};

export const Faqlist = () => {
    const { isUserId } = useContextex();
    const dispatch = useDispatch();
    const data = useSelector(state => state.data.FaqData);
    const { t } = useTranslation();
    const [faqList, setFaqList] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchDataAndSetFaqList = async () => {
            try {
                await dispatch(userFaqPage({ uid: isUserId }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataAndSetFaqList();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (data) {
            setFaqList(data);
        }
    }, [data]);

    const handleToggle = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <>
        <div className="main-content px-20 default">
            <div className="space-20"></div>
            <section className="flat-title inner-page">
                <div className="cl-container full">
                    <div className="row">
                        <div className="col-12">
                            <div className="content">
                                <h2>{t('Frequently asked questions')}</h2>
                                <ul className="breadcrumbs">
                                    <li><a className='pointer' onClick={() => navigate('/')}>{t('Home')}</a></li>
                                    <li>/</li>
                                    <li>{t('Frequently asked questions')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section flat-question style-1">
                <div className="cl-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section text-center">
                                <h2 className="wow fadeInUp">{t('Helps & FAQs')}</h2>
                                <div className="text wow fadeInUp">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}.</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="widget-tabs style-1">
                                <div className="widget-content-tab">
                                    <div className="widget-content-inner active">
                                        {faqList?.map((item, index) => (
                                            <div className="flat-accordion">
                                                <FAQItem
                                                    key={item?.id}
                                                    question={item?.question}
                                                    answer={item?.answer}
                                                    isActive={index == activeIndex}
                                                    onClick={() => handleToggle(index)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </>
    );
};
