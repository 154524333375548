import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchInquiryReq } from '../store/action';
import { useContextex } from '../context/useContext';
import { useTranslation } from 'react-i18next';
import { getData, getInquiryPro, getLoading, getSecoundData, getSendEnquiryData } from '../store/selector';
import { CustomSnackbar } from '../utils/CustomSnackbar';

function InquiryPage() {

    const [enquryData, setEnquryData] = useState(null)
    const [sendEReq, setSendEReq] = useState(false)
    const [btnText,setBtnText] = useState('')

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [isCalled,setIsCalled] = useState(false)

    const { productDetailId, isUserId, bookedProductData, sendedEnquiry, setSendedEnquiry } = useContextex();
    const dispatch = useDispatch();

    const data = useSelector(getInquiryPro)
    const loading = useSelector(getLoading);
    const { t } = useTranslation();

    const handleSendRequest = useCallback(async (event) => {
        setShowSnackbar(false)
        setIsCalled(true)
        event.preventDefault();
        setSendedEnquiry(true)
        const formData = {
            uid: isUserId,
            prop_id: productDetailId,
        };

        try {
            await dispatch(fetchInquiryReq(formData));
            setSendEReq(!sendEReq)
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }, [isUserId, productDetailId, dispatch]);

    useEffect(() => {
        if (data) {
            setShowSnackbar(true)
            setSnackMsg(data?.ResponseMsg)
            setEnquryData(data);
            setSendEReq(false)
            setSendedEnquiry(false)
        }
    }, [data])

    const isEnquiry = bookedProductData?.propetydetails?.is_enquiry;
    const isButtonDisabled = isEnquiry === 1;
    const buttonText = isEnquiry === 0
        ? `${t('Contact a Property Owner')}`
        : `${t('Contacted')}`;
    const buttonClass = `tf-button-primary w-full justify-content-center d-flex ${isEnquiry === 0 ? 'style-bg-white' : ''}`;

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
        setIsCalled(false)
    };

    return (
        <>
            <div className="schedule">
                <h4 className="wow fadeInUp justify-content-center d-flex">{t('Inquiry Status')}</h4>
                <div className="button-submit mt-5">
                    <button
                        // disabled={isButtonDisabled}
                        className={buttonClass}
                        onClick={handleSendRequest}
                    >{buttonText}<i className="icon-arrow-right-add"></i></button>
                </div>
            </div>
            {loading && (
                <div className="preload preload-container">
                    <div className="middle"></div>
                </div>
            )}
            {showSnackbar && isCalled && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}
        </>
    );
}

export default InquiryPage;
