import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContextex, usePayment } from "../context/useContext";
import { fetchBookedProductData } from "../store/action";
import { useNavigate } from "react-router-dom";
import { getBookData } from "../store/selector";

export const useBookProduct = ({booked_for}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bookedProduct = useSelector(getBookData);
    const bookFor = localStorage.getItem('bookFor')

    const {
        isUserId,
        bookedUserData,
        productAmount,
        bookedProductData,
        transactionId,
        selectedPaymentId,
        productFinalPrice,
        bookedOtherUserData,
        otherUserGender,
        setTransactionId,
        setSelectedPaymentId,
    } = useContextex();

    const { buyProperty } = usePayment()

    const storedBookData = localStorage.getItem('bookinUserData')
    const parsedBookData = JSON.parse(storedBookData)
    const storedAmountData = localStorage.getItem('productAmount')
    const parsedAmountData = JSON.parse(storedAmountData)
    const propId = localStorage.getItem('pid')
    const checkOut = localStorage.getItem('check_out')
    const days = localStorage.getItem('days')

    const countryCallingCode = `+${bookedOtherUserData?.phone.slice(0, 2)}`;
    const mobileNumber = bookedOtherUserData?.phone.slice(2);

    const createBookingData = useCallback(() => ({

        prop_id: bookedProductData?.propetydetails?.id || propId,
        uid: isUserId,
        check_in: bookedUserData?.checkIn || parsedBookData?.checkIn,
        check_out: bookedUserData?.checkOut || checkOut,
        subtotal: productAmount?.productTotalAmnt || parsedAmountData?.productTotalAmnt,
        total: productFinalPrice || parsedAmountData?.bookingTotalAmount,
        total_day: productAmount?.bookingDays || days,
        cou_amt: productAmount?.bookingCouponAmount || parsedAmountData?.bookingCouponAmount,
        wall_amt: productAmount?.bookingWalletAmount || parsedAmountData?.bookingWalletAmount,
        transaction_id: ['2', '5'].includes(selectedPaymentId) ? '0' : transactionId,
        add_note: bookedUserData?.notes || parsedBookData?.notes,
        prop_price: bookedProductData?.propetydetails?.price || parsedAmountData?.bookingAmount,
        book_for: bookedUserData?.bookedFor || parsedBookData?.bookedFor,
        p_method_id: selectedPaymentId || '12',
        tax: productAmount?.productTotalTax || parsedAmountData?.productTotalTax,
        noguest: bookedUserData?.noGuest || parsedBookData?.noGuest,
        ...(bookedUserData?.bookedFor === 'other' && {
            fname: bookedOtherUserData?.firstName,
            lname: bookedOtherUserData?.lastName,
            gender: otherUserGender,
            email: bookedOtherUserData?.email,
            mobile: mobileNumber,
            ccode: countryCallingCode,
            country: 'india'
        })
    }), [
        bookedProductData,
        bookedUserData,
        productAmount,
        productFinalPrice,
        selectedPaymentId,
        transactionId,
        isUserId,
        bookedOtherUserData,
        otherUserGender
    ]);

    const bookProduct = useCallback(async () => {
        if(transactionExecutedRef.current){
            return;
        }

        if ((booked_for == 'booking' || bookFor == 'booking')&& transactionId && !['2', '5'].includes(selectedPaymentId)) {
            try {                
                transactionExecutedRef.current = true
                await dispatch(fetchBookedProductData(createBookingData()));
                navigate('/dashboard')
            } catch (error) {
                console.error("Error booking product:", error);
            }
        }
    }, [transactionId]);

    const transactionExecutedRef = useRef(false);

    useEffect(() => {
        const handleTransaction = async () => {
            if (transactionId) {
                
                await bookProduct();
                await buyProperty();
                setTransactionId('');
                navigate('/dashboard');
                setSelectedPaymentId('');                
            }
        }

        handleTransaction()
    }, [transactionId]);

    // useEffect(() => {
    //     if (bookedProduct) {
    //         console.log('Booked Product:', bookedProduct);
    //     }
    // }, [bookedProduct]);

    return {
        bookedProduct,
        bookProduct,
    };
};
