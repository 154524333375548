import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextex, usePayment } from "../context/useContext";
import { useDispatch, useSelector } from "react-redux";
import { getWalletReportData, getWalletUpdate } from "../store/selector";
import { fetchWalletData, fetchWalletUpdate } from "../store/action";
import { IconWallet } from "@tabler/icons-react";
import { KhaltiPayment } from "../payment/KhaltiPayment";
import { FlutterwavePayment } from "../payment/FlutterwavePayment";
import { PaystackPayment } from "../payment/PaystackPayment";
import { RazorpayPayment } from "../payment/RazorpayPayment";
import { ReModal } from "../partials/ReModal";
import PaymentList from "../payment/PaymentList";
import { PaypalPayment } from "../payment/PaypalPayment";
import { StripePayment } from "../payment/StripePayment";
import SenangpayPayment from "../payment/SenangpayPayment";
import { PaytmPayment } from "../payment/PaytmPayment";
import { MidtransPayment } from "../payment/MidtransPayment";
import { TwoCheckoutPayment } from "../payment/TwoCheckoutPayment";
import PayfastPayment from "../payment/PayfastPayment";
import { MercadoPagoPayment } from "../payment/MercadoPagoPayment";
import { CustomSnackbar } from "../utils/CustomSnackbar";
import { useNavigate } from "react-router-dom";

export const WalletList = ({ activeName }) => {
    const [userWalletItem, setUserWalletItem] = useState(null);
    const [selectedPaymentId, setSelectedPaymentId] = useState('Razorpay');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [paymentTrigger, setPaymentTrigger] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(null);

    const { t } = useTranslation();
    const {
        isUserId,
        imageBaseUrl,
        userCurrency,
        paymentGatwayList,
        setWalletAddAmount,
        transactionId,
        walletAddAmount,
        userWalletAmount,
        setUserWalletAmount,
        setTransactionId,
        setWalletAmount
    } = useContextex();

    const dispatch = useDispatch();
    const walletReport = useSelector(getWalletReportData);
    const walletUpData = useSelector(getWalletUpdate);
    const { walletPayment } = usePayment();
    const navigate = useNavigate()

    useEffect(() => {
        if ((selectedPaymentId === 'Stripe' || selectedPaymentId === 'Paypal') && paymentTrigger) {
            setIsOpenModal(true);
        }
    }, [selectedPaymentId, paymentTrigger]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchWalletData({ uid: isUserId }));
            } catch (error) {
                console.error("Error fetching wallet data:", error);
            }
        };

        fetchData();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (walletReport) {
            setUserWalletItem(walletReport?.Walletitem);
            setUserWalletAmount(localStorage.getItem('avblWalletBlnc'));
        }
    }, [walletReport]);

    const updateWallet = async () => {

        try {
            if (walletAddAmount > 0 && transactionId) {
                await dispatch(fetchWalletUpdate({ uid: isUserId, wallet: walletAddAmount }));
                navigate('/dashboard');
                await walletPayment()
                setTransactionId('')
                setWalletAddAmount(0)
            }
        } catch (error) {
            console.error("Error updating wallet:", error);
        }
    };

    useEffect(() => {
        updateWallet()
        localStorage.setItem('walletAddAmount', walletAddAmount)
    }, [transactionId, walletAddAmount])

    const closeModal = () => setIsOpenModal(false);

    const product_name = "Subscribe Payment";
    const product_amount = walletAddAmount;
    const product_id = 1
    const filteredPaymentWay = paymentGatwayList?.filter(item => item?.p_show !== '0' && item?.id !== '2')
    const newFilteredData = filteredPaymentWay?.filter(item => !['4', '8', '10', '11', '13', '14', '15'].includes(item.id));

    return (
        <>

            <div className='d-flex mob-dash flex-col pt-5 pb-5'>
                <div className='col-10'>
                    <h3>{t('My Wallet')}</h3>
                    <div className="text-content">
                        {t('Your Current Wallet Balance is')} <span style={{ color: "red" }}>{userCurrency}{userWalletAmount}</span>
                    </div>
                </div>
            </div>

            <div className="grid-section-1">
                <div className="wg-box pl-44" >
                    <div className="container col-12 h-100">

                        <div className="col-sm-12 w-100 col-md-6 position-relative">
                            <div className='d-flex my-3 align-items-center'>
                                <div className='col-sm-12 col-md-12 col-xl-9 col-lg-9'>
                                    <h6>{t('Add Amount')}</h6>
                                </div>
                            </div>
                            <form>
                                <fieldset className="name">
                                    <input
                                        type="text"
                                        className='w-100'
                                        onChange={(e) => setWalletAddAmount(e.target.value)}
                                        id="galleryCategoryName"
                                        name="prop_title"
                                        placeholder="Add Amount"
                                        required
                                    />
                                </fieldset>
                            </form>
                        </div>

                        <div className="col-sm-12 w-100 col-md-6 position-relative">
                            <div className='d-flex my-3 align-items-center'>
                                <div className='col-sm-12 col-md-12 col-xl-9 col-lg-9'>
                                    <h6>{t('Select Payment Method')}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 h-100">
                            <div className="wrap" style={{ overflowY: 'auto', height: '70%' }}>
                                <div className="row gap-4 col-12 px-3 d-flex justify-content-between" >
                                    {newFilteredData?.map((item) => (
                                        <div key={item?.id} className={`categories-item col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 wow fadeInUp ${selectedPaymentId == item?.title ? 'active-map' : ''}`} onClick={() => setSelectedPaymentId(item?.title)}>
                                            <div className="icon">
                                                <img src={`${imageBaseUrl}${item?.img}`} alt={`${item?.title} icon`} />
                                            </div>
                                            <div>
                                                <div className="name">
                                                    <a>{item?.title}</a>
                                                </div>
                                                <div className="text">{item?.subtitle}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="wg-box" style={{ height: '100vh' }}>
                    <h4>{t('Recent Transactions')}</h4>
                    <ul className="wrap-recent-activities" style={{ overflowY: 'auto', height: '90%' }}>
                        {userWalletItem?.map((item, index) => (

                            <li className="recent-activities-item d-flex justify-content-between" key={index}>
                                <div><IconWallet /></div>
                                <div>
                                    <p>{item?.tdate}</p>
                                    <p>{item?.status}</p>
                                </div>

                                <p className={item?.status === 'Credit' ? 'text-primary' : 'text-warning'}>
                                    {userCurrency}{item?.amt}{item?.status === 'Credit' ? '+' : '-'}
                                </p>
                            </li>

                        ))}
                        {userWalletItem?.length == 0 && (
                            <div style={{ height: "400px" }} className='align-items-center justify-content-center mt-5 d-flex '>
                                <div>
                                    <h4 className='empty-message'>{t('Go & Add your Amount')}</h4>
                                </div>
                            </div>
                        )}
                    </ul>
                </div>

            </div>

            <div className="button-submit mt-10">
                <button className="tf-button-primary" onClick={() => setPaymentTrigger(true)}>
                    {t('Continue')}<i className="icon-arrow-right-add"></i>
                </button>
            </div>

            {selectedPaymentId === 'Khalti Payment' && paymentTrigger && <KhaltiPayment product_name={product_name} product_amount={product_amount} />}
            {selectedPaymentId === 'FlutterWave' && paymentTrigger && <FlutterwavePayment product_name={product_name} booked_for={'wallet'} product_amount={product_amount} product_id={product_id} />}
            {/* {selectedPaymentId === 'PayStack' && paymentTrigger && <PaystackPayment product_amount={product_amount} booked_for={'wallet'} />} */}
            {selectedPaymentId === 'Razorpay' && paymentTrigger && <RazorpayPayment product_amount={product_amount} booked_for={'wallet'} setPaymentTrigger={setPaymentTrigger} />}

            {selectedPaymentId === 'PayStack' && paymentTrigger &&
                <div onClick={() => setPaymentTrigger(false)} className="PayStack">
                    <div onClick={(e) => e.stopPropagation()} className="PayStack2">
                        <PaystackPayment product_amount={product_amount} booked_for={'wallet'} />
                    </div>
                </div>
            }

            {isOpenModal && selectedPaymentId === 'Stripe' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/def/transfer.jpg" alt="" />
                        <h3>{t('Complete Your Transaction')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex  flex-column'>
                            <StripePayment />
                        </form>
                    </div>
                </ReModal>
            }

            {isOpenModal && selectedPaymentId === 'Paypal' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/def/transfer.jpg" alt="" />
                        <h3>{t('Complete Your Transaction')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex align-items-center justify-content-center d-flex flex-column'>
                            <PaypalPayment product_amount={product_amount} />
                        </form>
                    </div>
                </ReModal>
            }

            {activeName === 'Wallet' && <PaymentList />}
            {selectedPaymentId === 'Paytm' && paymentTrigger && <PaytmPayment product_amount={product_amount} />}
            {selectedPaymentId === 'SenangPay' && paymentTrigger && <SenangpayPayment product_amount={product_amount} />}
            {selectedPaymentId === 'Midtrans' && paymentTrigger && <MidtransPayment product_amount={product_amount} />}
            {selectedPaymentId === '2checkout' && paymentTrigger && <TwoCheckoutPayment product_amount={product_amount} />}
            {selectedPaymentId === 'Payfast' && paymentTrigger && <PayfastPayment product_name={product_name} product_amount={product_amount} booked_for={'wallet'} />}
            {selectedPaymentId === 'MercadoPago' && paymentTrigger && <MercadoPagoPayment product_name={product_name} product_amount={product_amount} />}

        </>
    )
}