import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useContextex } from '../context/useContext';
import { checkMobileNumRequest, msg91OtpRequest, otpTypeRequest, registerUserDetails, twillioOtpRequest } from '../store/action';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getAuthTokenData, getLoading, getOTPData, getRegisterData, getRegisterUser, getSecoundData, getVarifyMobData } from '../store/selector';
import { useTranslation } from 'react-i18next';

function RegistrationPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [registerUser, setRegisterUser] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [validNumberData, setValidNumberData] = useState(null);
    const [checkNumber, setCheckNumber] = useState(null);
    const [snackMsg, setSnackMsg] = useState(null)
    const [otpSend, setOtpSend] = useState(false)
    const [resendOpt, setResendOtp] = useState(false)
    const [otpType, setOtpType] = useState('');
    const [otpValue, setOtpValue] = useState(Array.from({ length: 6 }, () => ''));
    const [sendOtpReq, setSendOtpReq] = useState(false);
    const [isCalled, setIsCalled] = useState(false)
    const [userOtp, setUserOtp] = useState('');
    const [errors, setErrors] = useState({
        name: '',
        password: '',
        email: '',
        phoneNumber: ''
    })

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { register } = useAuth();
    const { t } = useTranslation();
    const { setRegisterModal, registrData, setLoginData, setIsUser, setUserCurrency, setLoginModal } = useContextex();
    const registerUserData = useSelector(getRegisterData);
    const loading = useSelector(getLoading);
    const varifyMobileDt = useSelector(getVarifyMobData)
    const secoundData = useSelector(getSecoundData);
    const authToken = useSelector(getAuthTokenData);
    const otpData = useSelector(getOTPData)
    const inputRefs = useRef(Array.from({ length: 6 }, () => null));
    let concatenatedOtp = otpValue.join('');

    useEffect(() => {
        const modalElement = document.getElementById('modalregister');
        const modal = new Modal(modalElement);
        modal.show();
    }, []);

    useEffect(() => {
        if (registerUserData?.UserLogin) {
            const { id, currency } = registerUserData?.UserLogin;
            localStorage.setItem('uid', id);
            setIsUser(id);
            setUserCurrency(currency ? currency : '$');
            setLoginData(registerUserData);
        }
    }, [registerUserData, registrData, setIsUser, setUserCurrency, setLoginData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRegisterUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOtp = (index, value) => {
        const updatedOtpValue = [...otpValue];
        updatedOtpValue[index] = value;
        setOtpValue(updatedOtpValue);
        if (value && !isNaN(value) && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const Ccode = phoneNumber.slice(0, 2);
    const countryCallingCode = `+${Ccode}`;
    const mobileNumber = phoneNumber.slice(2);

    useEffect(() => {
        dispatch(otpTypeRequest()).catch(console.error);
    }, [dispatch]);

    useEffect(() => {
        if (secoundData?.SMS_TYPE) {
            setOtpType(secoundData.SMS_TYPE);
        } else {
            console.error("secoundData is missing SMS_TYPE.");
        }
    }, [secoundData]);

    useEffect(() => {
        if (isCalled && varifyMobileDt && varifyMobileDt.ResponseMsg) {
            setValidNumberData(varifyMobileDt.ResponseMsg);
            if (varifyMobileDt.ResponseMsg == 'New Number!') {
                setSnackMsg("Otp Send Successfull")
                setIsCalled(false)
            } else {
                setSnackMsg("Mobile Number Already Exist")
                setIsCalled(false)
            }
        } else {
            console.error("Valid number data is null or undefined.");
        }
    }, [varifyMobileDt, isCalled]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            if ((sendOtpReq || validNumberData === 'New Number!' && checkNumber)) {
                try {
                    const mobileNumber = phoneNumber;
                    const Ccode = phoneNumber.slice(0, 2);
                    const countryCallingCode = `+${Ccode}`;
                    if (otpType === 'Twilio') {
                        await dispatch(twillioOtpRequest({ mobile: mobileNumber }));
                        setResendOtp(false)
                        setCheckNumber(false)
                        setOtpSend(true)
                    } else {
                        await dispatch(msg91OtpRequest({ mobile: mobileNumber }));
                        setCheckNumber(false)
                        setResendOtp(false)
                        setOtpSend(true)
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchDataAndSetCategories();
    }, [sendOtpReq, checkNumber, validNumberData]);

    useEffect(() => {
        if (otpData) {
            setUserOtp(otpData);
        } else {
            console.error("OTP data is null or undefined.");
        }
    }, [otpData]);

    const validateInputs = () => {
        const newErrors = { name: '', email: '', password: '', phoneNumber: '' };
        let isValid = true;

        if (!registerUser.name) {
            newErrors.name = "Please Enter Your Name";
            isValid = false;
        }
        if (registerUser.password.length < 3) {
            newErrors.password = "Password must be at least 3 characters long";
            isValid = false;
        }
        if (!registerUser.email) {
            newErrors.email = "Please Enter Your Email";
            isValid = false;
        }
        if (!phoneNumber) {
            newErrors.phoneNumber = "Phone number can't be null";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const resetForm = () => {
        setPhoneNumber('');
        setRegisterUser({
            name: '',
            email: '',
            password: ''
        });
        setUserOtp('');
        setSendOtpReq('');
        setOtpValue('');
        setOtpType('');
        setResendOtp('');
        setValidNumberData(null)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const countryCallingCode = `+${phoneNumber.slice(0, 2)}`;
        const mobileNumber = phoneNumber.slice(2);
        console.log('userOtp', userOtp?.otp)
        console.log('concatenatedOtp', concatenatedOtp)
        if (!validateInputs()) return;
        setShowSnackbar(false)
        try {
            if (userOtp?.otp == concatenatedOtp) {
                await dispatch(registerUserDetails({
                    name: registerUser.name,
                    email: registerUser.email,
                    mobile: mobileNumber,
                    ccode: countryCallingCode,
                    password: registerUser.password
                }));
                setOtpSend(false)
                setShowSnackbar(true);
                resetForm()
                setRegisterModal(false)
                setLoginModal(false);
                setValidNumberData('')
                register(authToken)
                navigate('/')
                setRegisterUser('')
            } else {
                setShowSnackbar(true)
                setSnackMsg("Passwords don't match.")
            }

        } catch (error) {
            console.error("Error during registration:", error);
        }

    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const snackMessage = registerUserData?.ResponseMsg || snackMsg

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && !event.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleCheck = async (e) => {
        e.preventDefault()

        if (!validateInputs()) return;
        setShowSnackbar(false)

        try {
            await dispatch(checkMobileNumRequest({ mobile: mobileNumber, ccode: countryCallingCode }));
            await setCheckNumber(true)
            setShowSnackbar(true)
            setIsCalled(true)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleResetOtp = async (e) => {
        e.preventDefault()
        setShowSnackbar(false)

        try {
            const mobileNumber = phoneNumber;
            const Ccode = phoneNumber.slice(0, 2);
            const countryCallingCode = `+${Ccode}`;
            if (otpType === 'Twilio') {
                await dispatch(twillioOtpRequest({ mobile: mobileNumber }));
                setShowSnackbar(true)
                setResendOtp(false)
            } else {
                await dispatch(msg91OtpRequest({ mobile: mobileNumber }));
                setCheckNumber(false)
                setShowSnackbar(true)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <>
            <div className="modal fade modalCenter" id="modalregister">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal-sm">
                        <a className="btn-hide-modal pointer" data-bs-dismiss="modal">
                            <i className="icon-close pointer"></i>
                        </a>
                        <div className="image-left">
                            <img src="../assets/images/def/registerImg.jpg" alt="" />
                            <h3>{t('Join Us and Discover New Opportunities')}</h3>
                        </div>
                        <div className="content-right">
                            <h4>{t('Create an account')}</h4>
                            <form className="form-login" onSubmit={handleSubmit}>
                                {validNumberData === 'New Number!' && otpSend ? (
                                    <div className='d-flex'>
                                        <form className="form__login">
                                            <div className='row'>
                                                <div className="otp-field mb-4">
                                                    <p>*{t('Enter Your Otp Value')}</p>
                                                    {inputRefs.current.map((ref, index) => (
                                                        <input
                                                            key={index}
                                                            type="text"
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                            maxLength={1}
                                                            value={otpValue[index]}
                                                            onChange={(e) => handleChangeOtp(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                            style={{ height: '50px', width: '50px' }}
                                                            className='m-1'
                                                        />
                                                    ))}
                                                    <p className='mt-2'>{t(`Didn't receive code`)}?<span onClick={handleResetOtp} className='text-primary pointer'>{t('Resend New Code')}</span></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <>
                                        <fieldset className="name">
                                            <input
                                                className={`m-bottom ${errors.name ? 'border border-danger' : ''}`}
                                                tabIndex="2"
                                                aria-required="true"
                                                type='text'
                                                placeholder="Full Name"
                                                name="name"
                                                value={registerUser.name}
                                                onChange={handleChange}
                                            />
                                            <span className='span-text text-danger mx-4'>{errors.name}</span>
                                        </fieldset>
                                        <fieldset className="email">
                                            <input
                                                className={`m-bottom ${errors.email ? 'border border-danger' : ''}`}
                                                type='email'
                                                placeholder="Email Address"
                                                name="email"
                                                value={registerUser.email}
                                                onChange={handleChange}
                                                tabIndex="2"
                                                aria-required="true"
                                            />
                                            <span className='span-text text-danger mx-4'>{errors.email}</span>
                                        </fieldset>
                                        <fieldset className="phonenumber">
                                            <input
                                                className={`m-bottom ${errors.phoneNumber ? 'border border-danger' : ''}`}
                                                type='text'
                                                placeholder="Mobile Number (With Country Code)"
                                                name="phone"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                tabIndex="2"
                                                aria-required="true"
                                            />
                                            <span className='span-text text-danger mx-4'>{errors.phoneNumber}</span>
                                        </fieldset>
                                        <fieldset className="password">
                                            <input
                                                className={`m-bottom ${errors.password && 'border border-success'}`}
                                                type={"password"}
                                                placeholder="Password"
                                                name="password"
                                                value={registerUser.password}
                                                onChange={handleChange}
                                                tabIndex="2"
                                                aria-required="true"
                                            />
                                            <span className='span-text text-danger mx-4'>{errors.password}</span>
                                        </fieldset>
                                        <fieldset className="password">
                                            <input
                                                tabIndex="2"
                                                aria-required="true"
                                                type='text'
                                                placeholder="Referral code (optional)"
                                            />
                                        </fieldset>
                                        <div className="flex items-center justify-between">
                                            <div className="checkbox-item">
                                                <label>
                                                    <p>{t('I agree with terms & conditions')}</p>
                                                    <input type="checkbox" />
                                                    <span className="btn-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* {!registerUser.password && <span className='text-danger'>*Please Select terns & condition </span>} */}
                                {validNumberData === 'New Number!' ? (
                                    <div className="button-submit">
                                        <button className="tf-button-primary w-full justify-content-center" type="submit">
                                            {t('Register')}
                                            <i className="icon-arrow-right-add"></i>
                                        </button>
                                    </div>

                                ) : (
                                    <div className="button-submit">
                                        <button
                                            className="tf-button-primary w-full justify-content-center"
                                            onClick={handleCheck}
                                        >
                                            {t('Continue')}
                                            <i className="icon-arrow-right-add"></i>
                                        </button>
                                    </div>
                                )}
                            </form>

                            <div className="flex items-center justify-center">
                                <p>{t('Have an account')}?</p>
                                <button
                                    className="btn-show-register pointer"
                                    onClick={() => { setLoginModal(true); setRegisterModal(prev => !prev); }}
                                >
                                    {t('Log in')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMessage} />}
        </>
    );
}

export default RegistrationPage;
