import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContextex, usePayment } from '../context/useContext';
import { fetchBookedProductData } from '../store/action';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getBookData, getSecoundData } from '../store/selector';

export const AddToProductPay = ({ paybleWalletAmnt, selectedPaymentType, setSelectedPaymentType }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [bookedProductDetails, setBookedProductDetails] = useState([]);
    const [isBook, setIsBook] = useState(true)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        bookedUserData,
        productAmount,
        bookedProductData,
        transactionId,
        selectedPaymentId,
        isUserId,
        productFinalPrice,
        bookedOtherUserData,
        otherUserGender,
        setSelectedPaymentId,
    } = useContextex();

    const secoundData = useSelector(getSecoundData);
    const { buyProperty } = usePayment()
    const bookedProduct = useSelector(getBookData);
    const productTax = (productAmount?.bookingAmount || 0) * (productAmount?.bookingTax || 0) / 100;

    const countryCallingCode = `+${bookedOtherUserData?.phone.slice(0, 2)}`;
    const mobileNumber = bookedOtherUserData?.phone.slice(2);

    const bookingSuccessData = {
        prop_id: bookedProductData?.propetydetails?.id,
        uid: isUserId,
        check_in: bookedUserData?.checkIn,
        check_out: bookedUserData?.checkOut,
        subtotal: productAmount?.bookingAmount,
        total: productFinalPrice,
        total_day: productAmount?.bookingDays,
        cou_amt: productAmount?.bookingCouponAmount || '0',
        wall_amt: productAmount?.bookingWalletAmount || paybleWalletAmnt,
        transaction_id: (selectedPaymentId === '2' || selectedPaymentId === '5') ? '0' : transactionId,
        add_note: bookedUserData?.notes,
        prop_price: bookedProductData?.propetydetails?.price,
        book_for: bookedUserData?.bookedFor,
        p_method_id: selectedPaymentId,
        tax: productTax,
        noguest: bookedUserData?.noGuest,
        ...(bookedUserData?.bookedFor === 'other' && {
            fname: bookedOtherUserData?.firstName,
            lname: bookedOtherUserData?.lastName,
            gender: otherUserGender,
            email: bookedOtherUserData?.email,
            mobile: mobileNumber,
            ccode: countryCallingCode,
            country: 'india'
        })
    };

    const handleTransaction = useCallback(async () => {
        if (transactionExecutedRef.current) {
            return;
        }

        if (isBook && selectedPaymentId && selectedPaymentType && (selectedPaymentId === '2' || selectedPaymentId === '5')) {
            try {
                transactionExecutedRef.current = true;

                await dispatch(fetchBookedProductData(bookingSuccessData));

                setSelectedPaymentId('0');
                await buyProperty();
                setIsBook(false);
                setShowSnackbar(true);
                setSelectedPaymentType(null);
                navigate('/dashboard');
            } catch (error) {
                console.error("Error booking product:", error);
            }
        }

    }, [isBook, selectedPaymentId, selectedPaymentType, dispatch, navigate]);

    useEffect(() => {
        handleTransaction();
    }, [handleTransaction]);

    const transactionExecutedRef = useRef(false);

    useEffect(() => {
        if (bookedProduct) {
            setIsBook(false)
        }
    }, [isBook, bookedProduct])

    useEffect(() => {
        if (secoundData) {
            setBookedProductDetails(secoundData);
            if (selectedPaymentId === '5' || selectedPaymentId === '2') {
                navigate('/dashboard');
                setSelectedPaymentId('0')
            }
        } else {
            console.error("Payment details are null or undefined.");
        }
    }, [selectedPaymentId]);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
};
