import { createContext, useState } from "react";
import { getBookData, getPackData, getWalletUpdate } from "../store/selector";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../utils/CustomSnackbar";
import { useNavigate } from "react-router-dom";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");

    const bookedProduct = useSelector(getBookData);
    const addToWallet = useSelector(getWalletUpdate);
    const payPackAmnt = useSelector(getPackData);
    const dispatch = useDispatch();

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handlePaymentResponse = async (response) => {
        const { ResponseCode, ResponseMsg } = response;
        if (ResponseCode == '200') {
            await setShowSnackbar(true);
            await setSnackMsg(response?.ResponseMsg);
            window.location.href = '/dashboard';

        } else {
            await setSnackMsg(ResponseMsg);
        }
    };

    const payDirect = async () => {
        if (bookedProduct) {
            await handlePaymentResponse(bookedProduct);
        }
    };

    const buyProperty = async () => {
        // if (bookedProduct) {
        //     await handlePaymentResponse(bookedProduct);
        // }

        setShowSnackbar(true);

        const propResponse = localStorage.getItem('bookProResp')
        setSnackMsg(propResponse)

        // if (showSnackbar) {
        //     return (
        //         <CustomSnackbar
        //             handleClose={handleCloseSnackbar}
        //             snack_message={"hello"}
        //         />
        //     )
        // }
    };

    const walletPayment = async () => {
        setShowSnackbar(true);

        const walletResponse = localStorage.getItem('walletresponse');
        setSnackMsg(walletResponse);
        // alert(walletResponse);
        // window.location.href = window.location.origin;
        // window.location.href = '/';

        // if (showSnackbar) {
        //     return (
        //         <CustomSnackbar
        //             handleClose={handleCloseSnackbar}
        //             snack_message={snackMsg}
        //         />
        //     );
        // }
    };

    const payForPackage = async () => {
        setShowSnackbar(true);

        const walletResponse = localStorage.getItem('packResp');
        setSnackMsg(walletResponse);
        // alert(walletResponse);

        // window.location.href = window.location.origin;

        if (showSnackbar) {
            return (
                <CustomSnackbar
                    handleClose={handleCloseSnackbar}
                    snack_message={snackMsg}
                />
            );
        }
    }

    const paymentFailedCase = async () => {
        alert('Oops! Payment failed. Please try again.')
    }

    return (
        <>
            <PaymentContext.Provider value={{ payDirect, walletPayment, buyProperty, payForPackage, paymentFailedCase }}>
                {children}
            </PaymentContext.Provider>
            {showSnackbar && snackMsg && (
                <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />
            )}
        </>
    );
};
