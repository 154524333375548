import React, { useEffect, useState } from 'react';
import { useContextex, usePayment } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { useBookProduct } from '../hooks/useBookProduct';
import AddProparty from '../addProparty/AddProparty';
import { usePackagePurchase } from '../hooks/usePackagePurchase';

export const RazorpayPayment = ({ product_amount, booked_for, setPaymentTrigger }) => {
  const { bookedOtherUserData, loginUserData, setTransactionId, transactionId } = useContextex();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();

  // Call the hook to get its return value or to perform side effects
  const { performBookProduct } = useBookProduct(booked_for = { booked_for });
  const { performBookPackage } = usePackagePurchase();
  const { paymentFailedCase } = usePayment()

  const newAmount = Math.round(product_amount * 100);

  useEffect(() => {
    const loadRazorpay = () => {

      const options = {
        key: 'rzp_test_oyEnEu70MpsDyP',
        amount: newAmount,
        currency: 'INR',
        name: 'Go Property',
        description: 'Test Transaction',
        image: 'https://yourlogo.com/logo.png',
        handler: (response) => handlePaymentSuccess(response),
        modal: {
          ondismiss: () => {
             paymentFailedCase();
          },
        },
        prefill: getPrefillData(),
        notes: { address: 'Corporate Office' },
        theme: { color: '#3399cc' },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    loadRazorpay();
  }, [product_amount, navigate, setTransactionId]);

  const handlePaymentSuccess = (response) => {
    // setShowSnackbar(true);
    setTransactionId(response?.razorpay_payment_id);
    setPaymentStatus(booked_for);
  };

  const getPrefillData = () => ({
    name: bookedOtherUserData?.firstName || loginUserData?.UserLogin?.name,
    email: bookedOtherUserData?.email || loginUserData?.UserLogin?.email,
    contact: bookedOtherUserData?.phone || loginUserData?.UserLogin?.mobile,
  });

  useEffect(() => {
    if (paymentStatus === 'booking') {
      if (performBookProduct) {
        performBookProduct();
        setShowSnackbar(true)
        navigate('/dashboard')
      }
    } else if (paymentStatus === 'package') {
      if (performBookPackage) {
        performBookPackage()
        setShowSnackbar(true)

        navigate('/addProparty')
      }
    } else if (paymentStatus === 'wallet') {
      if (transactionId) {
        navigate('/dashboard')
      }
    }
  }, [paymentStatus, transactionId, performBookPackage, performBookProduct, navigate]);

  return null

};
