import React, { useEffect, useMemo } from 'react'
import { Tabs, TabsCard } from '../partials/Main'
import { useContextex } from '../context/useContext';
import { fetchData } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import { getData } from '../store/selector';
import { usePropertyList } from '../hooks/usePropertyList';

function AllProduct() {

    const data = useSelector(getData);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { setCurrentPage, tabCardData, imageBaseUrl, userCurrency, selectedId, setSelectedId, tabsList, setTabCardData, selectedCountryId, isUserId, setProductDetailId, setIsLikedOrNot } = useContextex();
    const { setFeaturedPropList, setPropertyType, setTabsList } = usePropertyList();

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchData('u_cat_wise_property.php', { cid: selectedId, uid: isUserId || '0', country_id: selectedCountryId ? selectedCountryId : 1 }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, selectedId, selectedCountryId]);

    useEffect(() => {
        if (data && data?.Property_cat) {
            setTabCardData(data.Property_cat);
        }
    }, [data, setTabCardData]);

    useEffect(() => {
        setCurrentPage('all_product')
    }, [setCurrentPage])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        var tabs = function () {
            $('.widget-tabs').each(function () {
                $(this).find('.widget-content-tab').children().hide();
                $(this).find('.widget-content-tab').children(".active").show();
                $(this).find('.widget-menu-tab').children('.item-title').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive);
                    contentActive.addClass('active').fadeIn("slow");
                    contentActive.siblings().removeClass('active');
                    $(this).addClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive).siblings().hide();
                });
            });
            $('.widget-tabs-1').each(function () {
                $(this).find('.widget-content-tab-1').children().hide();
                $(this).find('.widget-content-tab-1').children(".active-1").show();
                $(this).find('.widget-menu-tab-1').children('.item-title-1').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive);
                    contentActive.addClass('active-1').fadeIn("slow");
                    contentActive.siblings().removeClass('active-1');
                    $(this).addClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive).siblings().hide();
                });
            });
        };

        return tabs()
    }, [])

    const navigate = useNavigate()
    const { pid } = useParams()

    const handleItemClick = (item) => {
        const formattedText = item.title.replace(/\s+/g, '_').toLowerCase();
        setProductDetailId(item.id);
        navigate(`/properties/${formattedText}`);
        localStorage.setItem('pid', item?.id);
        localStorage.setItem('formattedText', formattedText);
    };

    return (
        <>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="main-content">

                        <div className="flat-title page-property-grid-2">
                            <div className="cl-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="content">
                                            <h2 className="wow fadeInUp">{t('Real Estate & Homes For Sale')}</h2>
                                            <ul className="breadcrumbs wow fadeInUp">
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-tabs style-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <ul className="widget-menu-tab">
                                                {tabsList?.map((item) => (
                                                    <li key={item?.id} className={`item-title d-flex align-items-center ${selectedId === item?.id ? 'active' : ''}`} onClick={() => setSelectedId(item?.id)}>
                                                        <span className="inner">{item?.title}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="property-grid-wrap-v2">
                            <div className="cl-container">
                                <div className="row">
                                    {tabCardData?.map((item, index) => (
                                        <div className="col-xl-4 col-md-6 pointer" key={index} onClick={() => handleItemClick(item)}>
                                            <div className="box-dream has-border wow fadeInUp" onClick={() => {
                                                setProductDetailId(item.id);
                                                setIsLikedOrNot(prevState => !prevState);
                                            }}>

                                                <div className="image">
                                                    <div className="list-tags">
                                                        <a className="tags-item for-sell">{item?.buyorrent == '2' ? 'FOR BUY' : `⭐${item.rate}`}</a>
                                                    </div>
                                                    <div className=" arrow-style-1 pagination-style-1">
                                                        <div className="">
                                                            <div className="">
                                                                <div className="">
                                                                    <img className="" style={{ minHeight: '400px', maxHeight: '400px' }} src={`${imageBaseUrl}${item?.image}`} alt={item?.title} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="content" >
                                                    <div className="head">
                                                        <div className="title">
                                                            <a>{item.title.substring(0, 35)}</a>
                                                        </div>
                                                        <div className="price">{userCurrency ? userCurrency : '$'}{item.price}{item?.buyorrent == '1' && ` /night`}</div>
                                                    </div>
                                                    <div className="location">
                                                        <div className="icon">
                                                            <i className="flaticon-location"></i>
                                                        </div>
                                                        <p>{item.city}</p>
                                                    </div>
                                                    <div className="icon-box">
                                                        <div className="item">
                                                            <i className="flaticon-hotel"></i>
                                                            <p>{item?.beds} {t('Beds')}</p>
                                                        </div>
                                                        <div className="item">
                                                            <i className="flaticon-bath-tub"></i>
                                                            <p>{item?.bathroom} {t('Baths')}</p>
                                                        </div>
                                                        <div className="item">
                                                            <i className="flaticon-minus-front"></i>
                                                            <p>{item?.sqrft} Sqft</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                    {tabCardData?.length == 0 &&
                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center" style={{ height: '300px' }}>
                                            <h6 className='empty-message'>Sorry, there is no any nearby</h6>
                                            <h6 className='empty-message'>category or data not found</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AllProduct
