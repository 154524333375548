import React, { useEffect, useMemo, useState } from 'react'
import { useContextex } from '../context/useContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchUserCheckIn, fetchUserCheckOut, fetchUserConfirmProp, fetchReviewReq } from '../store/action';
import { IconCopy } from '@tabler/icons-react';
import Cancel from '../booking/Cancel';
import { ReModal } from '../partials/ReModal';
import { Rating } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getData, getMyPropBookHand, getReviewUp } from '../store/selector';

function Receipt({ dropedReview, setDropedReview }) {
    const { isUserId, bookingId, complateCurrentBook, confirmCurrentBook, showCancelModal, setShowCancelModal, loginUserData, bookedOtherUserData, currentPage } = useContextex();
    const [receiptData, setReceiptData] = useState([])
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState('')

    const [isConfirmBtn, setIsConfirmBtn] = useState(false)
    const [isCheckInBtn, setIsCheckInBtn] = useState(false)
    const [isCheckOutBtn, setIsCheckOutBtn] = useState(false)
    const [isReviewBtn, setIsReviewBtn] = useState(false)
    const [isCancelBtn, setIsCancelBtn] = useState(false)

    const [checkInButton, setCheckInButton] = useState(false);
    const [checkOutButton, setCheckOutButton] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(null);
    const [reviewText, setReviewText] = useState('');
    const [reviewRating, setReviewRating] = useState(0);
    const [bookData, setBookData] = useState(null);
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const data = useSelector(getData)
    const myBookHand = useSelector(getMyPropBookHand)
    const myReviewResp = useSelector(getReviewUp)

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                if (currentPage == 'addproparty') {
                    await dispatch(fetchData('my_book_details.php?', { book_id: bookingId, uid: isUserId }));
                } else {
                    await dispatch(fetchData('u_book_details.php?', { book_id: bookingId, uid: isUserId }));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, bookingId, isCheckOutBtn, isCheckInBtn, isConfirmBtn, isReviewBtn, isCancelBtn]);

    useEffect(() => {
        if (data && data?.bookdetails) {
            setReceiptData(data?.bookdetails);
        }
    }, [data, receiptData]);

    const fetchDataOrder = async () => {
        try {
            if (confirmBtn) {
                await handleConfirm();
            } else if (checkInButton) {
                await handleCheckIn();
            } else if (checkOutButton) {
                await handleCheckOut();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const handleConfirm = async () => {
        try {
            await dispatch(fetchUserConfirmProp({ uid: isUserId, book_id: bookingId }));
            setShowSnackbar(true)
            setIsConfirmBtn(true)
        } catch (error) {
            console.error("Error confirming booking:", error);
        }
    };

    const handleCheckIn = async () => {
        try {
            await dispatch(fetchUserCheckIn({ uid: isUserId, book_id: bookingId }));
            setShowSnackbar(true)
            setIsCheckInBtn(true)
        } catch (error) {
            console.error("Error checking in:", error);
        }
    };

    const handleCheckOut = async () => {
        try {
            await dispatch(fetchUserCheckOut({ uid: isUserId, book_id: bookingId }));
            setShowSnackbar(true)
            setIsCheckOutBtn(true)
        } catch (error) {
            console.error("Error checking out:", error);
        }
    };

    useEffect(() => {
        fetchDataOrder();
    }, [confirmBtn, checkInButton, checkOutButton]);

    useEffect(() => {
        if (myBookHand) {
            setSnackMsg(myBookHand?.ResponseMsg)
        }
    }, [myBookHand])

    useEffect(() => {
        if (data) {
            setBookData(data);
        } else {
            console.error("Data is null or undefined.");
        }
    }, [data]);

    useEffect(() => {
        if (myReviewResp) {
            setSnackMsg(myReviewResp?.ResponseMsg)
        }
    }, [myReviewResp])

    const handleCopy = (text) => {
        setShowSnackbar(true)
        navigator.clipboard.writeText(text)
            .then(() => {
                setSnackMsg('Text copied to clipboard:');
            })
            .catch(err => {
                setSnackMsg('Failed to copy!');
            });
    };

    const closeModal = () => setIsOpenModal(false);
    const subTotalAmount = receiptData?.subtotal * receiptData?.total_day

    const starStyle = {
        fontSize: '4rem',
    };

    const handleDropReview = useMemo(() => async (event) => {
        event.preventDefault();

        try {
            const formData = {
                uid: isUserId,
                book_id: receiptData?.book_id,
                total_rate: reviewRating,
                rate_text: reviewText
            };

            const response = await dispatch(fetchReviewReq(formData));
            setShowSnackbar(true)
            setIsReviewBtn(true)
            if (response.status === 'success') {
                setDropedReview(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsOpenModal(false)
    }, [isUserId, dispatch, reviewRating, reviewText, isOpenModal, dropedReview]);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            <div className="main-content ">

                <div className="shop-order-wrap">
                    <div className="cl-container">
                        <div className="row justify-center">
                            <div className="col-xl-8">
                                <div className="order-completed">
                                    <div className="order-info">
                                        <div className="item">
                                            <p>{t('Book Id')}</p>
                                            <p>{receiptData?.book_id}</p>
                                        </div>
                                        <div className="item">
                                            <p>{t('Booking Date')}</p>
                                            <p>{receiptData?.book_date}</p>
                                        </div>
                                        <div className="item">
                                            <p>{t('Sub Total')}</p>
                                            <p>${receiptData?.subtotal}</p>
                                        </div>
                                        <div className="item">
                                            <p>{t('Payment Method')}</p>
                                            <p>{receiptData?.payment_title}</p>
                                        </div>
                                    </div>

                                    <div className="sidebar-shop">

                                        <div className="sidebar-shop-item your-order">
                                            <div className="title">{t('Booking Schedule')}</div>

                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Booking Date')}</div>
                                                <div className="text">{receiptData?.book_date}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Check in')} </div>
                                                <div className="text">{receiptData?.check_in}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Check out')}</div>
                                                <div className="text">{receiptData?.check_out}</div>
                                            </div>
                                            {receiptData?.check_intime && <div className="flex items-center justify-between">
                                                <div className="text">{t('CheckIn')} {t('Date&Time')}</div>
                                                <div className="text">{receiptData?.check_intime}</div>
                                            </div>
                                            }
                                            {receiptData?.check_outtime && <div className="flex items-center justify-between">
                                                <div className="text">{t('CheckOut')} {t('Date&Time')}</div>
                                                <div className="text">{receiptData?.check_outtime}</div>
                                            </div>
                                            }
                                            {receiptData?.noguest && <div className="flex items-center justify-between">
                                                <div className="text">{t('Number Of Guest')}</div>
                                                <div className="text">{receiptData?.noguest}</div>
                                            </div>
                                            }

                                        </div>

                                        <div className="sidebar-shop-item your-order">
                                            <div className="title">{t('Booking Details')}</div>

                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Name')}</div>
                                                <div className="text">
                                                    {currentPage === 'addproparty'
                                                        && (bookedOtherUserData?.firstName ? bookedOtherUserData?.firstName : receiptData?.customer_name)
                                                    }

                                                    {currentPage === 'dash-board' && (receiptData?.fname ? receiptData.fname : loginUserData?.UserLogin?.name)}
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Phone Number')}</div>
                                                <div className="text">
                                                    {currentPage === 'addproparty'
                                                        && (bookedOtherUserData?.phone || receiptData?.customer_mobile)
                                                    }
                                                    {currentPage === 'dash-board' && (
                                                        <>
                                                            {receiptData?.ccode || loginUserData?.UserLogin?.ccode} &nbsp;
                                                            {receiptData?.mobile || loginUserData?.UserLogin?.mobile}
                                                        </>
                                                        // receiptData?.mobile ?  receiptData?.mobile : loginUserData?.UserLogin?.mobile
                                                    )}
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Payment Title')}</div>
                                                <div className="text">{receiptData?.payment_title}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Payment Status')}</div>
                                                <div className="text">{receiptData?.p_method_id === '2' ? 'Unpaid' : 'Paid'}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Booking Status')}</div>
                                                <div className="text">{receiptData?.book_status}</div>
                                            </div>
                                            {receiptData?.transaction_id != 0 && (
                                                <div className="flex mobile-view-tid items-center justify-between">
                                                    <div className="text">{t('Transaction Id')}</div>
                                                    <div className="text w-sm-100 transaction-id  flex flex-row">{receiptData?.transaction_id}
                                                        <span className='pointer ' onClick={() => handleCopy(receiptData?.transaction_id)}>
                                                            <IconCopy />
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="sidebar-shop-item your-order">
                                            <div className="title">{t('Price Section')}</div>

                                            <div className="divider"></div>

                                            <div className="flex items-center mobile-view-tid justify-between">
                                                <div className="text">{t('Property Price')}</div>
                                                <div className="text transaction-id-days">({receiptData?.prop_price} X {receiptData?.total_day}{t('days')})</div>
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Sub Total')}</div>
                                                <div className="text">${receiptData?.subtotal}</div>
                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="text-1">{t('Tax')}</div>
                                                <div className="text-1">${receiptData?.tax}</div>
                                            </div>

                                            {receiptData?.wall_amt > 0 && (
                                                <div className="flex items-center justify-between">
                                                    <div className="text-1">{t('Wallet Amount')}</div>
                                                    <div className="text-1 text-danger">${receiptData?.wall_amt}</div>
                                                </div>
                                            )}

                                            {receiptData.cou_amt > 0 && (
                                                <div className="flex items-center justify-between">
                                                    <div className="text-1">{t('Coupon Amount')}</div>
                                                    <div className="text-1 text-danger">${receiptData?.cou_amt}</div>
                                                </div>
                                            )}

                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between">
                                                <div className="text-1">{t('Total')}</div>
                                                <div className="text-1">${receiptData?.total}</div>
                                            </div>

                                        </div>

                                        {receiptData?.add_note && (
                                            <div className='sidebar-shop-item your-order'>
                                                <div className="title p-0 m-0">{t('Note')}:</div>
                                                <p className='p-0 m-0'>{receiptData?.add_note}</p>
                                            </div>
                                        )}

                                    </div>

                                    <div className="main-content">
                                        {confirmCurrentBook && receiptData && currentPage === 'addproparty' && (
                                            <div className="d-flex mt-5 justify-content-between">
                                                {receiptData?.book_status !== 'Cancelled' && (
                                                    <a className="tf-button-primary style-black active mx-2" onClick={() => setShowCancelModal(!showCancelModal)}>
                                                        {t('Cancel Booking')}
                                                    </a>
                                                )}

                                                {receiptData?.book_status == 'Booked' && (
                                                    <a className="tf-button-primary active" onClick={() => {
                                                        setCheckInButton(false);
                                                        setConfirmBtn(true);
                                                        setCheckOutButton(false);
                                                    }}>
                                                        {t('Confirm')} <i className="icon-arrow-right-add"></i>
                                                    </a>
                                                )}

                                                {receiptData?.book_status == 'Confirmed' && (
                                                    <a className="tf-button-primary active" onClick={() => {
                                                        setCheckOutButton(false);
                                                        setCheckInButton(true);
                                                        setConfirmBtn(false);
                                                    }}>
                                                        {t('Check In')}
                                                    </a>
                                                )}

                                                {(receiptData?.book_status == 'Check_in') && (
                                                    <a className="tf-button-primary active" onClick={() => {
                                                        setCheckOutButton(true);
                                                        setCheckInButton(false);
                                                        setConfirmBtn(false);
                                                    }}>
                                                        {t('Check Out')}
                                                    </a>
                                                )}

                                            </div>
                                        )}

                                        {currentPage != 'addproparty' && receiptData?.is_rate == '0' && receiptData?.book_status === 'Completed' && (
                                            <a className="mt-5 tf-button-primary active" onClick={() => setIsOpenModal(true)}>{t('Drop Review')}</a>
                                        )}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOpenModal && (
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex align-items-center justify-content-center d-flex flex-column'>
                            <div className="leave-a-review w-75">
                                <h4 className="wow fadeInUp">{t('Leave A Review')}</h4>
                                <div>
                                    <p className="wow font-weight-bold fadeInUp">{t('How was your experience')}</p>
                                    <div>
                                        <Rating
                                            name="custom-size"
                                            defaultValue={1}
                                            precision={0.5}
                                            icon={<span style={starStyle}>&#9733;</span>}
                                            emptyIcon={<span style={starStyle}>&#9734;</span>}
                                            onChange={(event, newValue) => setReviewRating(newValue)}
                                        />
                                    </div>
                                </div>
                                <form className="form-comment">
                                    <p className="wow font-weight-bold fadeInUp">{t('Write Your Review')}</p>
                                    <fieldset className="message wow fadeInUp has-top-title">
                                        <textarea name="message" rows="4" placeholder="Your Review here..." className="" tabindex="2" aria-required="true" required onChange={(e) => setReviewText(e.target.value)}></textarea>
                                    </fieldset>
                                    <div className='d-flex justify-content-between'>
                                        <div className="button-submit wow fadeInUp">
                                            <button className="tf-button-primary style-black active" type="submit" onClick={() => setIsOpenModal(false)}>{t('Maybe Later')} </button>
                                        </div>
                                        <div className="button-submit wow fadeInUp">
                                            <button className="tf-button-primary" type="submit" disabled={!reviewText && !reviewRating} onClick={handleDropReview}>{t('Submit Review')} <i className="icon-arrow-right-add"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                </ReModal>
            )}
            {showCancelModal && <Cancel url={'u_my_book_cancle.php'} setIsCancelBtn={setIsCancelBtn} />}
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}
        </>
    )
}

export default Receipt

