import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase configuration - Get these from your Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyDI0H7HXm-aP1TTQXzLZfHV98mnK9v8NCs",
  authDomain: "studio-67236.firebaseapp.com",
  projectId: "studio-67236",
  storageBucket: "studio-67236.appspot.com",
  messagingSenderId: "324152946798",
  appId: "1:324152946798:web:7a293bdf554049c215f67f",
  measurementId: "G-SC775CQY59"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Firebase Auth, and Realtime Database
const db = getFirestore(app);
const auth = getAuth(app);
const database = getDatabase(app);

const vapidKey = "BBeGwOVXkJxZbDM0ejqdRLINKHp834QGmMeaVpeSulMeD28OUIQ2QHlG1Ew_U6ejitDIQV_E3zDuCZ_SnyRNd8E";
const messaging = getMessaging(app);

export const RecvestToken = async () => {
  return Notification.requestPermission()
    .then((permisson) => {
      if (permisson === "granted") {
        const Token = getToken(messaging, { vapidKey })
        console.log("<<================= Tokrn :-->> ",Token);
        return Token;
      }
    })
}

// Export Firebase services
export { db, auth, database, messaging };
