import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useContextex, usePayment } from '../context/useContext';
import { usePackagePurchase } from '../hooks/usePackagePurchase';
import { useBookProduct } from '../hooks/useBookProduct';
import { uid } from 'uid';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchWalletUpdate, packagePlanBuy } from '../store/action';


const SuccessHandler = ({ booked_for }) => {
  const {
    setTransactionId,
    transactionId,
    setPaymentStatus,
  } = useContextex();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { performBookProduct } = useBookProduct(booked_for = { booked_for });
  const { performBookPackage } = usePackagePurchase();

  // Get necessary data from localStorage
  const bookFor = localStorage.getItem('bookFor');
  const packId = localStorage.getItem('packId');
  const paymentName = localStorage.getItem('paymentName');
  const walletAddAmount = localStorage.getItem('walletAddAmount');
  const uid = localStorage.getItem('uid');
  const packageAmount = localStorage.getItem('packageAmnt');
  const parsedAmount = packageAmount ? JSON.parse(packageAmount) : {};

  // Handle package purchase
  const purchasePackage = useCallback(async () => {
    if (!transactionId) {
      console.error('Transaction ID is missing, cannot proceed with package purchase.');
      return;
    }

    if (transactionExecutedRef.current) {
      return
    }

    try {
      transactionExecutedRef.current = true
      await dispatch(packagePlanBuy({
        uid,
        transaction_id: transactionId,
        plan_id: packId,
        pname: paymentName,
        wall_amt: parsedAmount?.packageWalletAmount,
      }));
      setTransactionId(null)
    } catch (error) {
      console.error('Error purchasing package:', error);
      setPaymentStatus('failed');
    } finally {
      navigate('/dashboard')
    }
  }, [transactionId]);

  const walletAmountAdd = useCallback(async () => {
    if (!transactionId) {
      console.error('Transaction ID is missing, cannot proceed with package purchase.');
      return;
    }

    // Check if the function has already been executed
    if (transactionExecutedRef.current) {
      return; // Exit if it has already been executed
    }

    try {
      // Mark as executed to prevent multiple calls
      transactionExecutedRef.current = true;

      await dispatch(fetchWalletUpdate({
        uid,
        wallet: walletAddAmount,
      }));

      setTransactionId(null);
    } catch (error) {
      console.error('Error purchasing package:', error);
      setPaymentStatus('failed');
    } finally {
      navigate('/dashboard');
    }
  }, [transactionId]);

  // Add a ref to track the execution status
  const transactionExecutedRef = useRef(false);

  const handlePaymentSuccess = useCallback(async () => {
    try {
      await setTransactionId(Date.now());

      if (booked_for === 'booking' && transactionId) {
        await performBookProduct();
      } else if (bookFor === 'package') {
        await purchasePackage();
      } else if (bookFor === 'wallet') {
        await walletAmountAdd()
      }

      setPaymentStatus('completed');
    } catch (error) {
      console.error('Error during payment success handling:', error);
      setPaymentStatus('failed');
    }
  }, [performBookProduct, purchasePackage, walletAmountAdd, setTransactionId, transactionId, booked_for, bookFor, setPaymentStatus]);

  useEffect(() => {
    handlePaymentSuccess();
  }, [handlePaymentSuccess]);

  return null;
};

export default SuccessHandler;


export const CancelHandler = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const snackMessage = 'Your transaction was Failed';
  const {
    setTransactionId,
  } = useContextex();
  const navigate = useNavigate()
  const { paymentFailedCase } = usePayment()
  
  useEffect(() => {
    navigate('/')
  }, [])

  function handlePaymentSuccess() {
    setShowSnackbar(true)
    setTransactionId('')
  }

  const handleCloseSnackbar = () => setShowSnackbar(false);

  window.onload = function () {
    handlePaymentSuccess();
  };

}